import React from "react";
import { createBrowserRouter } from "react-router-dom";
import App from "../home/App";
import ErrorPage from "../error/ErrorPage";
import Services from "../services/Services";
import { GitHub, X, Telegram, LinkedIn, InnerServiceCloud } from "./Redirects";
import EntryPage from "../entry/EntryPage";

export const router = createBrowserRouter([
    {
      path: "/",
      element: <EntryPage />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/app",
      element: <App />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/services",
      element: <Services />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/github",
      element: <GitHub />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/linkedin",
      element: <LinkedIn />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/xtwt",
      element: <X />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/tg",
      element: <Telegram />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/services/inner-service-cloud",
      element: <InnerServiceCloud />,
      errorElement: <ErrorPage />,
    }
  ]
);