import './RedirectPage.css';
import HappyMac from '../../assets/icons/HappyMac.svg';
import Link from '../link/Link';
import ReactTyped from 'react-typed';
import { useEffect } from 'react';

const RedirectPage = (props) => {
    const text = "You will be shortly redirected to your destination.\nIf you are not redirected, please click the link below.\n";
    useEffect(() => {
        document.title = "HMRS";
    }, []);
    return (
        <div className="RedirectPage">
            <div className="centered-redirect">
                <img src={HappyMac} alt="Happy Mac" />
                <div className="RedirectPage-text">
                    <p className="RedirectPage-title">Happy Mac Redirect System (HMRS)</p>
                </div>
                {text.split("\n").map((string) => (
                    <p className="RedirectPage-subtitle">{string}</p>
                ))}
                <Link link={props.link} text={props.text} />
            </div>
            <div className="ServicesPage-footer">
                <p className="ServicesPage-footer-text">। {" "}
                    <ReactTyped
                        strings={["ॐ असतो मा सद्गमय ।", "तमसो मा ज्योतिर्गमय ।", "मृत्योर्मा अमृतं गमय ।", "ॐ शान्तिः शान्तिः शान्तिः ॥"]}
                        typeSpeed={90}
                        loop
                        backSpeed={30}
                        showCursor={false}
                    />
                </p>
                <p className="ServicesPage-footer-text-bottom">may we all reach the light</p>
            </div>
        </div>
    );
}

export default RedirectPage;