import ErrorPage from "../error/ErrorPage"
import RedirectPage from "./RedirectPage"
import { Offline, Online } from "react-detect-offline"

export function RedirectTo(props) {
    const url = props.url
    const text = props.text
    window.location.replace(url)
    return (
        <RedirectPage link={url} text={text} />
    )
}

export function ALittleMoreAbstract(props) {
    const url = props.url
    const text = props.text
    return (
        <div>
            <Online>
                <RedirectTo url={url} text={text} />
            </Online>
            <Offline>
                <ErrorPage />
            </Offline>
        </div>
    )
}

export function GitHub() {
    const url = "https://github.com/gaganmalvi"
    return (
        <ALittleMoreAbstract url={url} text="GitHub" />
    )
}

export function LinkedIn() {
    const url = "https://www.linkedin.com/in/gaganmalvi/"
    return (
        <ALittleMoreAbstract url={url} text="LinkedIn" />
    )
}

export function X() {
    const url = "https://x.com/malviscape"
    return (
        <ALittleMoreAbstract url={url} text="X" />
    )
}

export function Telegram() {
    const url = "https://t.me/BigChadCat"
    return (
        <ALittleMoreAbstract url={url} text="Telegram" />
    )
}

export function InnerServiceCloud() {
    const url = "https://cloud.malvi.infra"
    return (
        <ALittleMoreAbstract url={url} text="Cloud" />
    )
}