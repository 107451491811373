import sadmac from "../../assets/icons/sadmac.svg";
import { useRouteError } from "react-router-dom";
import "./ErrorPage.css";
import { useEffect } from "react";
import ReactTyped from "react-typed";

const ErrorPage = () => {
    const text = "An illegal request was made and the Sad Mac Reporting System (SMRS) raised a fatal exception.\nA highly trained team of monkeys have been dispatched to deal with this situation.";
    var error = useRouteError();
    useEffect (() => {
        document.title = "Error | SMRS";
    }, []);
    if (!error) {
        error = false;
    }
    return (
        <div className="ErrorPage">
            <div className="centered-error">
                <img src={sadmac} alt="Sad Mac" />
                <div className="ErrorPage-text">
                    <p className="ErrorPage-title">Sad Mac Reporting System (SMRS)</p>
                </div>
                {text.split("\n").map((string) => (
                    <p className="ErrorPage-subtitle">{string}</p>
                ))}
            </div>
            <div className="ErrorPage-footer">
                <p className="ErrorPage-footer-text">You can also report this error to the SMRS team by clicking <a className="email-link-error" href="mailto:malvigagan@gmail.com">here</a>.</p>
                <p className="ErrorPage-footer-text">Error: {error.statusText || error.message || "Unknown error occured. Please check your internet connection."} </p>
            </div>
            <div className="ServicesPage-footer">
                <p className="ServicesPage-footer-text">। {" "}
                    <ReactTyped
                        strings={["ॐ असतो मा सद्गमय ।", "तमसो मा ज्योतिर्गमय ।", "मृत्योर्मा अमृतं गमय ।", "ॐ शान्तिः शान्तिः शान्तिः ॥"]}
                        typeSpeed={90}
                        loop
                        backSpeed={30}
                        showCursor={false}
                    />
                </p>
                <p className="ServicesPage-footer-text-bottom">may we all reach the light</p>
            </div>
        </div>
    );
};

export default ErrorPage;