import React, { useEffect, useState } from 'react';
import { useSpring, animated } from 'react-spring';
import "./Animated.css"

const Animated = () => {
  const [isVisible, setIsVisible] = useState(false);

  // Use the useSpring hook to create a spring animation
  const spring = useSpring({
    opacity: isVisible ? 1 : 0,
    marginTop: isVisible ? 0 : 20,
    config: { duration: 800 },
  });

  useEffect(() => {
    // Trigger the animation when the component mounts
    setIsVisible(true);
  }, []);

  return (
    <div>
      {/* Wrap each paragraph in an animated.div */}
      <animated.p className="text-header" style={spring}>retreat</animated.p>
      <animated.p className="text-header" style={spring}>rearchitect</animated.p>
      <animated.p className="text-header" style={spring}>redevelop</animated.p>
      <animated.p className="text-header" style={spring}>restart</animated.p>
      <animated.p className="text-header" style={spring}>reinforce</animated.p>
      {/* Add more paragraphs as needed */}
    </div>
  );
};

export default Animated;
