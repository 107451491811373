import "./Services.css";
import HappyMac from "../../assets/icons/HappyMac.svg"
import ReactTyped from "react-typed";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { InnerServiceCloud } from "../redirects/Redirects";
import Link from "../link/Link";

const Services = () => {
    const navigate = useNavigate();
    useEffect(() => {
        document.title = "HMSP";
    }, []);
    return (
        <div className="ServicesPage">
            <div className="ServicesPage-header">
                <img className="ServicesPage-header-img" src={HappyMac} alt="HappyMac" onClick={() => navigate(-1)}/>
                <p className="ServicesPage-header-text">Happy Mac Service Portal (HMSP)</p>
            </div>
            <div className="ServicesPage-body">
                <p className="ServicesPage-body-header">This portal is meant for authorized users only. You will need to log in with your organization provided credentials.</p>
                <p className="ServicesPage-body-text">You may need to log in to Tailscale to access these services.</p>
                <p className="ServicesPage-body-text">If you have any queries, please contact the administrator.</p>
                <div className="ServiceBox">
                    <div className="ServiceItem">
                        <Link link="/services/inner-service-cloud" text="Cloud" />
                    </div>
                </div>
            </div>
            <div className="ServicesPage-footer">
                <p className="ServicesPage-footer-text">। {" "}
                    <ReactTyped
                        strings={["ॐ असतो मा सद्गमय ।", "तमसो मा ज्योतिर्गमय ।", "मृत्योर्मा अमृतं गमय ।", "ॐ शान्तिः शान्तिः शान्तिः ॥"]}
                        typeSpeed={90}
                        loop
                        backSpeed={30}
                        showCursor={false}
                    />
                </p>
                <p className="ServicesPage-footer-text-bottom">may we all reach the light</p>
            </div>
        </div>
    )
};

export default Services;