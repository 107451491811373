import MacChime from '../../assets/sounds/mac-chime.mp3';
import HappyMac from '../../assets/icons/HappyMac.svg';
import FileGraphics from '../../assets/icons/File-graphic.svg';
import Global from '../../assets/icons/Global.svg';
import Folder from '../../assets/icons/Folder.svg';
import FontKit from '../../assets/icons/Font-kit.svg';
import Print from '../../assets/icons/Print.svg';
import SaveAs from '../../assets/icons/Save-as.svg';
import NewGraphic from '../../assets/icons/New-graphic.svg';
import './EntryPage.css';
import App from '../home/App';

export default function EntryPage() {
    const audio = new Audio(MacChime);
    // Check if viewport is mobile
    const isMobile = window.innerWidth <= 500;
    if (isMobile) {
        return (
            <App />
        )
    }
    return (
        <div className='EntryPage'>
            <div className='centered-login'>
                <div className='mac-system-login'>
                    <div className='login-text'>
                        <div className='login-text-header'>
                            <img src={HappyMac} alt='HappyMac' className='login-text-header-img' />
                        </div>
                        <p className='login-text-body'>Starting up...</p>
                        <button className='login-text-button' onClick={() => {
                            audio.play();
                            document.getElementsByClassName('login-text-button')[0].style.display = 'none';
                            document.getElementsByClassName('login-text-body')[0].style.display = 'block';
                            for (let i = 1; i <= 7; i++) {
                                setTimeout(() => {
                                    document.getElementById(i).style.display = 'block';
                                }, 300 * i);
                            }
                            setTimeout(() => {
                                window.location.href = '/app';
                            }, 2700);
                        }}>Continue</button>
                    </div>
                </div>
            </div>
            <div className='entrypage-footer'>
                <div className='dummy-footer-entry'>

                </div>
                <img src={FileGraphics} alt='File Graphics' className='icon-bottom' id='1' />
                <img src={Global} alt='Global' className='icon-bottom' id="2" />
                <img src={Folder} alt='Folder' className='icon-bottom' id="3" />
                <img src={FontKit} alt='Font Kit' className='icon-bottom' id="4" />
                <img src={Print} alt='Print' className='icon-bottom' id="5" />
                <img src={SaveAs} alt='Save As' className='icon-bottom' id="6" />
                <img src={NewGraphic} alt='New Graphic' className='icon-bottom' id="7" />
            </div>
        </div>
    )
}